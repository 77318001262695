import React from "react"
import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Stack from "../../../../../components/stack"
import InlineHelp from "../../../../../components/inline-help"
import Paragraph from "../../../../../components/paragraph"
import Note from "../../../../../components/note"
import NotationGroup from "../../../../../components/notation-group"
import PoemFruehlingNotation from "../../../../../content/kurse/jahreszeiten/01-jahreszeitengedicht/poem-fruehling-notation"
import TaskSidebar from "../../../../../components/task-sidebar"
import PaperStack from "../../../../../components/paper-stack"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  return (
    <LayoutCourse
      as="form"
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/jahreszeiten/01-jahreszeitengedicht/verben/loesung" />
      }
    >
      <Seo title="Verben" />
      <Stack>
        <Heading as="h1" level={2}>
          Verben bestimmen
        </Heading>
        <Paragraph>Und wie sieht es mit den Verben aus?</Paragraph>

        <TaskSidebar
          main={
            <PaperStack>
              <NotationGroup
                onNotationsUpdated={(notations) => {
                  setAnswer({
                    courseId: "jahreszeiten",
                    chapterId: "01-jahreszeitengedicht",
                    taskId: "verben",
                    answer: notations,
                  })
                }}
              >
                <PoemFruehlingNotation />
              </NotationGroup>
            </PaperStack>
          }
          sidebar={
            <Stack space={6}>
              <Note variant="task">
                Markiere alle Verben im Gedicht, indem du sie anklickst. Klicke
                erneut auf ein Wort, um die Markierung wieder zu entfernen.
              </Note>
              <InlineHelp title="Was sind nochmal Verben?">
                Verben sind sogenannte Tätigkeitswörter. Sie beschreiben, was
                jemand tut oder was geschieht.
              </InlineHelp>
            </Stack>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
